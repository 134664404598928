<template>
    <!-- <div class="welcome tw-flex tw-justify-center sm:tw-items-center sm:tw-p-0 tw-py-4 sm:tw-h-screen main-welcome-body">
        <div class="tw-flex tw-items-center tw-bg-white tw-w-11/12  tw-rounded-md tw-relative sm-tw-py-6 welcome-body">
            <div class="tw-w-full tw-text-center sm-py-8 sm-px-12 tw-p-4">
                <h2 class="page_speed_1904149950 page_speed_1662472061 page_speed_861092159">This Quiz Will Only Take About 2&nbsp;Minutes To Complete</h2>
                <p class="page_speed_720609814 page_speed_1662472061 page_speed_861092159">Click the button below to get started!</p><br>
            </div>
            <div class="tw-absolute bg-bottombar tw-bottom-0 tw-bg-bottombar tw-flex tw-py-3 tw-rounded-b-md tw-w-full tw-cursor-pointer tw-justify-center welcome-footer">
                <div id="submit" class="tw-text-white tw-text-lg tw-font-semibold tw-px-4 tw-flex tw-items-center tw-gap-1 add-title">
                    Start
                </div>
            </div>
        </div>
    </div> -->
    <div class="tw-flex tw-justify-center sm:tw-items-center sm:tw-p-0 tw-py-4 sm:tw-h-screen main-welcome-body">
        <div class="step tw-flex tw-items-center tw-bg-white welcome-body tw-w-11/12 tw-rounded-md tw-relative sm-tw-py-6  md:font" id="modal_0">
                    <div class="tw-w-full tw-text-center sm-py-8 sm-px-12 tw-p-4">
                        <div class="text-question sm:tw-text-3xl tw-text-2xl tw-font-bold tw-text-opacity-75" id="question-title-523">Email Address </div>
                        <div class="text-description tw-text-xl tw-font-semibold tw-mt-4">Type a Description</div>
                        <input type="text" class="tw-w-full tw-text-gray-500 tw-border input-border tw-rounded-md sm:tw-h-12 tw-h-10 tw-mt-7 tw-px-4" id="answer_523" name="answer_523">
                        <div class="tw-text-left tw-pb-6 tw-text-xs text-question-label">Enter email address</div>
                    </div>
                    <div class="tw-absolute tw-bottom-0 tw-bg-bottombar tw-flex tw-py-3 tw-rounded-b-md tw-w-full tw-cursor-pointer tw-justify-between">
                                                    <div class="tw-text-white tw-text-lg tw-font-semibold tw-px-4 tw-flex tw-items-center tw-gap-1 tw-invisible">
                                PREVIOUS
                            </div>
                                                <div class="form-error-message tw-hidden" id="question-error-523" role="alert">This field is required.</div>
                                                    <div id="submit" onclick="formsubmit(&quot;#modal_0&quot;,&quot;email-question&quot;,&quot;523&quot;)" class="tw-text-white tw-text-lg tw-font-semibold tw-px-4 tw-flex tw-items-center tw-gap-1">
                                SUBMIT
                                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                                </svg>
                            </div>
                                            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent} from 'vue'
// import '../../index.css' 
export default defineComponent({
    data(){
        return{
        }
    },
})
</script>

<style scoped>
        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,400;0,500;0,900;1,300;1,400;1,700&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,400;0,500;0,900;1,300;1,400;1,700&display=swap');
       
        .step.slide-in-right {
            animation: slide-in-right 0.5s ease-out forwards;
        }

        .step.slide-in-left {
            animation: slide-in-left 0.5s ease-out forwards;
        }

        @keyframes  slide-in-right {
            0% {
                transform: translateX(100%);
            }

            100% {
                transform: translateX(0);
            }
        }

        @keyframes  slide-in-left {
            0% {
                transform: translateX(-100%);
            }

            100% {
                transform: translateX(0);
            }
        }

        @keyframes  shake {
            10%, 90% {
                transform: translate3d(-1px, 0, 0);
            }

            20%, 80% {
                transform: translate3d(2px, 0, 0);
            }

            30%, 50%, 70% {
                transform: translate3d(-4px, 0, 0);
            }

            40%, 60% {
                transform: translate3d(4px, 0, 0);
            }
        }
        .shake{
            animation: shake 550ms cubic-bezier(.36,.07,.19,.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
        }
        .border-color-red{
            border-color: red;
        }
        .form-error-message {
            font-size: 0.75em;
            line-height: 1.5;
            background: rgba(0, 0, 0, 0.7);
            color: #fff;
            padding: 0.4em 0.8em;
            font-weight: 300;
            border-radius: 3px;
            font-family: 'Poppins', sans-serif !important;
        }
        .bg-image{
            background-image: url(https://app.quizforma.com/storage/images/template_1.jpg);
        }
        .bg-color{
            background-color: #f1f5f9;
        }
        .bg-bottombar{
            background-color: #a09ae4;
        }
        .text-question{
            color: #000000;
        }
        .text-description{
            color: #c4c7ca;
        }
        .input-border{
            border-color: #a09ae4;
        }
        .input-border:focus{
            outline-color: #4438ca;
        }
        .button-text{
            color: #a09ae4;
        }
        .button-background:hover{
            background-color: #4438ca;
        }
        .text-question-label{
            color: #a09ae4;
        }
        .danger-color{
            color:red !important;
        }
        .border-button{
            border-width: 1.5px !important;
        }
        .welcome h2{
            font-family: sans-serif !important;
            font-size: 25px;
            font-weight: bolder;
            margin-bottom: 20px;
        }
        .welcome p{
            font-family: sans-serif !important;
            font-size: 18px;
            margin: 0px;
        }
        .add-title{
            font-size: 18px;
        }
        .welcome-body{
            width: 832px;
        }
        .welcome-footer{
            padding-top: 14px !important;
            padding-bottom: 14px !important;
            }
        @media (max-width: 576px) { 
            .main-welcome-body{
                height: 92vh;
            }
            .welcome-body{
                    width: 91.666667%;
            }
            }
         @media (min-width: 640px){
        .sm-tw-py-6 {
            padding-top:24px !important;
            padding-bottom:24px !important;
        }
        .sm-py-8 {
            padding-top: 32px !important;
            padding-bottom: 32px !important;
        }
        .sm-px-12 {
                padding-left: 48px !important;
                padding-right: 48px !important;
            }
        }
</style>
<style scoped>
*{
  font-family: 'Merriweather', serif;
}
</style>